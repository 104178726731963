import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class Btrfs extends React.Component
{

   
    
    
    render() 
    {
        const {IndexedDBLink} = this;
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>
            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Faire des backups intélligents</h1>

                <p className="article-text">
                    Il nous est arrivé parfois de supprimer par erreur des fichiers ou de duppliquer des fichiers sql pour revenir à un état antérieur de la base de données.<br />
                    Le premier cas nous force à tout recommencer et le deuxieme à remplir le disque dur à chaque date de backup ect ...<br />

                    Il existe un systeme de fichier qui permet de dupliquer des fichiers en n'enregistrant seulement les octets de differences.<br />
                    Ainsi, si le fichier fait initialement 200Mo, la prochaine copie du fichier qui fera 205Mo ne prendra que 5M réellement sur le disque dur.<br /> 
                    Ça fait un gain de place énorme et aucune nécessité d'installer un programme spécialisé pour faire des backups ne sera nécésaire. <br /><br />

                    Btrfs ne gere pas les sauvegardes lui même, 
                    il est là seulement pour écrire les fichiers sur le disque dur de façon intéligente. C'est à nous de structurer les dossiers et fichiers.<br />
                    pour les récupérer ultérieurement. Un gestionnaire de backups sera un plus mais pas Indispensable.
                </p>

                <h2 className="article-title-2">Démonstration</h2>
                <p className="article-text">
                    J'ai formaté une clé usb avec gparted en btrfs et je l'ai rempli de fichiers pour saturer la clé usb.<br />
                    On remarque un volume0 qui est un sous-volume btrfs pour faire nos backups en bleu.<br />
                    Btrfs appel ça des "snapshot".
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-10.png" alt="btrfs image 1" />

                <p className="article-text">
                    J'ai crée un fichier texte dans le volume pour finir de saturer la clé usb.
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-12.png" alt="btrfs image 2" />

                <p className="article-text">
                    Arrivé à 505 lignes ça enregistre encore.
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-22.png" alt="btrfs image 3" />

                <p className="article-text">
                    Arrivé à 517 lignes ça enregistre encore mais on est saturé car la prochaine fois ça ne veut plus enregistrer.
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-23.png" alt="btrfs image 4" />

                <p className="article-text">
                    Saturation de la partion btrfs.
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-23_1.png" alt="btrfs image 6" />

                <p className="article-text">
                    Je recrée le fichier jusqu'a environ 510 lignes.
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-27.png" alt="btrfs image 7" />

                <p className="article-text">
                    Puis pour ma premiere sauvegarde (snapshot), je modifie la premiere ligne du fichier en "v1".
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-28.png" alt="btrfs image 8" />

                <p className="article-text">
                    Je fais un snapshot du volume pour une version 1 du fichier texte.<br />
                    Par logique les sauvegardes ne devant pas être affichées dans les dossiers, je décide de préceder d'un point le nom de la sauvagarde. 
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-29.png" alt="btrfs image 9" />

                <p className="article-text">
                    Je fais de même pour toutes les autres versions du fichier texte. 
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-29_1.png" alt="btrfs image 10" />

                <p className="article-text">
                    Toujours pas de saturation. 
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-30.png" alt="btrfs image 10" />

                <p className="article-text">
                    Toujours pas de saturation. 
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-30_1.png" alt="btrfs image 12" />

                <p className="article-text">
                    Toujours pas de saturation... ainsi de suite jusqu'a "v5"
                </p>
                <img src="/static/image/articles/btrfs/2022-12-11_10-30_2.png" alt="btrfs image 13" />
                
                <p className="article-text">
                    Pour récupérer le fichier à une version donnée il suffit de d'utiliser la commande "cp" &nbsp; (linux)
                </p>
                <Code language="bash" tabs="5">
                {`                     
                    $ cp .volume0_5/test.txt volume0/test.txt 
                    $ less  volume0/test.txt 
                `}
                </Code>
                <Code language="bash" tabs="5">
                {`
                    V5
                    123
                    456
                    789
                    ABC
                    DEF
                    GHI
                    123
                    456
                `}
                </Code>

                <p className="article-text">
                    Dans ce test concret, j'ai mis en évidence que les fichiers sauvegardés par des snapshot sont bien enregistrés au niveau des nouveaux octets ajoutés au fichier.<br /> 
                    Ce qui prouve que le systeme de fichier btrfs est tout à fait adapter pour faire des sauvegardes intéligente de fichier contrairement à ext4 ou ntfs.<br /> 
                    <br /> 
                    Ce systeme de fichier me permettra de mettre en place un serveur de sauvegarde pour sauvegarder mes notes de travail.
                    <br /> 
                    <br />
                    Btrfs a encore plein d'autres qualités comme la compression transparente pour l'utilisateur et le contrôle des données corrompues.<br /> 
                    <br /> 
                </p>




            </div>
        </main>
        )
    }
} 