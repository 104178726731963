
import React from "react";

import { CodeBlock, a11yDark} from 'react-code-blocks';

export default class Code extends React.Component {

    render()
    {
        const codeStyle = {
            fontFamily: 'Manrope',
            border: '2px solid black',
            letterSpacing: 2,
            fontSize: 18
        };

        let text = this.props.children;
        text = text.split("\n");
        
        const tabUnitaire = "    ";
        const tabs = tabUnitaire.repeat(this.props.tabs);
        let _texts = [];
        for (let index = 0; index < text.length; index++) {
            const elm = text[index];
            _texts[index] = elm.replace(tabs, '')
        }
        text = _texts.join("\n");

        return (
            <p style={codeStyle} className="codeblock">
                <CodeBlock
                    text={text.trim()}
                    theme={a11yDark}
                    language={this.props.language}
                />
            </p>
        );
    }
}