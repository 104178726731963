import React from "react";

export default class CodingamePage extends React.Component
{
    
    render() 
    {
        return (
            <main>
                <div className="into">
                    <h1 className="h1">
                        Florent Corlouër
                    </h1>
                    <h2>
                        Développeur fullstack - DevOps - Analyste Développeur
                    </h2>
                    <p className="card">
                        Page en cours de developpement
                    </p>
                </div>
            </main>
        )
    }
} 