import React from "react";

import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link
  } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

export default class SideNav extends React.Component
{
    
    render() 
    {
        return (
            <div className={`app-menu ${this.props.isMenuOpen ? "menu-open" : ""}`} style={{opacity: this.props.isMenuOpen ? 1 : 0}}>
                <div className="tools">
                    <AiOutlineClose onClick={e => this.props.handleMenu()} />
                </div>
                
                <ul onClick={e => this.props.handleMenu()}>
                    <li>
                        <Link to="/">Accueil</Link>
                    </li>
                    {/* <li>
                        <Link to="/codin-game">Codin Game</Link>
                    </li> */}
                    <li>
                        <Link to="/articles">Articles</Link>
                    </li>
                </ul>
            </div> 
        )
    }
} 