import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class IndexedDB extends React.Component
{

    IndexedDBLink() {
        return (
            <a href="https://developer.mozilla.org/fr/docs/Web/API/IndexedDB_API" target="_blank">IndexedDB</a>
        )
    }
    
    render() 
    {
        const {IndexedDBLink} = this;
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>
            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Du noSQL dans le navigateur web</h1>

                <p className="article-text">
                    Vous avez l'habitude d'utiliser le localstorage en javascript mais 
                    il peut arriver pour des raisons de performance ou de choix technique que celui devient trop petit car il est limité à environs 5 Mo.<br />
                    Ce n'est pas, grave, il exite une api dans votre navigateur web moderne qui permet de stocker une grande quantité de données.<br />
                    <br />
                    Cette APi se nomme <IndexedDBLink />. C'est une base de données de type noSQL.<br />
                    Le quotat autotisé par domaine est limité à 10% du disque dur par nom de domaine. <br />
                    Ce qui nous permet à tous d'avoir plusieurs Gigas de disponible et de plus, les données n'auront pas besoin de sérialisation avec JSON.stringify<br />
                    ces données seront directement enregistrer en variable javascript. La seule limite c'est que les fonctions membre ne seront pas enregistré. <br />
                    <br />
                    En partant du constat que nous avont besoin de la même façon stocker des données avec le localstorage, il nous faudra adapter <IndexedDBLink /> <br />
                    à la façon dont le localstorage enregistre les données.

                </p>

                <h2 className="article-title-2">Exemple d'utilisation</h2>
                {/* <p className="article-text">Indispensable notamment sur rapsberry pi</p> */}
                <Code language="javascript" tabs="5">
                {`                     
                     async getSave() {
                         const data = await this.CacheService.getItem('key1');
                         console.log('getSave data', data);
                     }
                 
                     async setSave() {
                         const data = await this.CacheService.setItem('key1', 'ma data');
                         console.log('setSave Ok', data);
                     }

                     async removeSave() {
                         const data = await this.CacheService.removeItem('key1');
                         console.log('removeSave Ok', data);
                     }
                 
                `}
                </Code>


                <h2 className="article-title-2">La classe complete</h2>
                <Code language="bash" tabs="5"> 
                {`
                
                     // https://developer.mozilla.org/en-US/docs/Web/API/IDBDatabase
                     // https://developer.mozilla.org/en-US/docs/Web/API/IDBObjectStore
                     export default class CacheService  {
                     
                         constructor() {
                     
                             this.db = null;
                             
                         }
                     
                         async getConnexion() {
                             await this.dbCreate();
                             const db = await this.dbConnect();
                             this.db = db;
                     
                             return db;
                         }
                     
                         async getItem(name) {
                     
                             await this.getConnexion();
                     
                             const transaction = this.db.transaction('storage', "readonly");
                             const objectStore = transaction.objectStore('storage');
                     
                             const data = await this.dbGetItem(objectStore, name);
                             // console.log('get save item', data);
                             this.db.close();
                             
                             
                             if (data === undefined) return undefined;
                             
                             return  Object.hasOwnProperty.call(data, 'data') ?  data.data : undefined;
                         }
                     
                         async setItem(name, value) {
                     
                             await this.getConnexion();
                     
                             const transaction = this.db.transaction('storage', "readwrite");
                             const objectStore = transaction.objectStore('storage');
                     
                             const data = await this.dbSetItem(objectStore, {key: name, data: value});
                     
                             this.db.close();
                             
                             return data
                         }
                     
                         async removeItem(name) {
                     
                             await this.getConnexion();
                     
                             const transaction = this.db.transaction('storage', "readwrite");
                             const objectStore = transaction.objectStore('storage');
                     
                             const data = await this.dbRemoveItem(objectStore, name)
                     
                             this.db.close();
                             
                             return data
                         }
                     
                         async initTest() {
                     
                             await this.dbCreate();
                             const db = await this.dbConnect();
                             // console.log('initTest db', db); 
                             const transaction = db.transaction('storage', "readwrite");
                     
                             const objectStore = transaction.objectStore('storage');
                             
                             await this.dbSetItem(objectStore, {key: 'key1', data: 'test1'});
                     
                     
                             // console.log('initTest db close'); 
                             db.close();
                         }
                     
                     
                         
                         // creation du connector
                         dbCreate() {
                             var DBOpenRequest = window.indexedDB.open("mydatabase", 4);
                             
                             return new Promise((resolve, reject) => {
                                 
                                 DBOpenRequest.onupgradeneeded = function(event) {
                     
                                     try {
                                         let db = this.result;
                                         let objectStore = db.createObjectStore("storage", { keyPath: "key" });
                                         objectStore.createIndex("data", "data", { unique: false });
                     
                                         console.log('Database created success.');
                                         resolve(true);
                                         
                                     } catch (error) {
                                         console.log('Database created error.');
                                         reject(false);
                                     }
                       
                                     
                                     
                                 };
                     
                                 DBOpenRequest.onsuccess = (event) => { resolve(true); };
                                 DBOpenRequest.onerror = (event) => { reject(false); };
                     
                             });
                         }
                         // creation du connector
                         dbConnect() {
                             var DBOpenRequest = window.indexedDB.open("mydatabase", 4);
                             
                             return new Promise((resolve, reject) => {
                                 
                                 DBOpenRequest.onsuccess = function(event) {
                                 
                                     // console.log('Database initialised.');
                                     
                                     const db = DBOpenRequest.result;
                                     resolve(db);
                                     
                                 };
                                 DBOpenRequest.onerror = function(event) {
                                 
                                     console.error('Database connexion failed.');
                                     
                                     reject(false);
                                     
                                 };
                             });
                         }
                     
                         
                     
                     
                         async dbGetItem(objectStore, keyName) {
                             const objectStoreTitleRequest = objectStore.get(keyName);
                             return new Promise((resolve, reject) => {
                                 
                                 objectStoreTitleRequest.onsuccess = function(event) {
                                 
                                     const data = objectStoreTitleRequest.result;
                                     // console.log('objectStore get success.');
                                     
                                     resolve(data);
                                     
                                 };
                                 objectStoreTitleRequest.onerror = function(event) {
                                 
                                     console.error('objectStore get failed.');
                                     
                                     reject(false);
                                     
                                 };
                             });
                         }
                     
                         async dbSetItem(objectStore, data) {
                             // IDBRequest
                             const IDBRequest = objectStore.put(data);
                             return new Promise((resolve, reject) => {
                                 
                                 IDBRequest.onsuccess = function(event) {
                                     
                                     // console.log('objectStore put success.', event);
                                     
                                     resolve(true);
                                     
                                 };
                                 IDBRequest.onerror = function(event) {
                                 
                                     console.error('objectStore put error.');
                                     
                                     reject(false);
                                     
                                 };
                             });
                         }
                         async dbRemoveItem(objectStore, name) {
                             // IDBRequest
                             const IDBRequest = objectStore.delete(name);
                             return new Promise((resolve, reject) => {
                                 
                                 IDBRequest.onsuccess = function(event) {
                                     
                                     console.log('objectStore delete success.', event);
                                     
                                     resolve(true);
                                     
                                 };
                                 IDBRequest.onerror = function(event) {
                                 
                                     console.error('objectStore delete error.');
                                     
                                     reject(false);
                                     
                                 };
                             });
                         }
                         
                     }
                     
                     
                     
                `}
                </Code>

            </div>
        </main>
        )
    }
} 