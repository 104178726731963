import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class OvhCloudServerLowCost extends React.Component
{
    
    render() 
    {
        
        return (
           

        <main>
            <Helmet>
                
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>

            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Un serveur dédié pour à peine 5 euros par mois</h1>

                <p className="article-text justify">
                    Chez OVH il existe un service `public cloud`.<br />
                    Pour ce qui découvre ce concept c'est un service destinée à fournir des ressources informatiques telles que des machines virtuelles, du stockage et des services de réseau aux entreprises et aux particuliers.<br />
                    <br />
                    OVH propose donc des machines virtuelles (VM) dans le cadre de son offre de Public Cloud. <br />
                    Les machines virtuelles sont des environnements informatiques isolés et autonomes exécutés sur un matériel physique partagé.<br />

                </p>

                <h2 className="article-title-2">Les instances</h2>
                <p className="article-text">
                    Une instance fait référence à une copie exécutable d'une machine virtuelle. <br />
                    Les instances sont créées à partir d'une image ou d'un modèle de machine virtuelle et sont exécutées sur des serveurs physiques. <br />
                    Elles sont utilisées pour exécuter des services, des charges de travail et des systèmes d'exploitation de manière isolée les uns des autres.<br />
                    Il est possible d'installer Docker sur des instances OVH.<br /><br />
                </p>

                
                <h2 className="article-title-2">Le prix</h2>
                <p className="article-text">
                    OVH propose des options de facturation à l'heure et au mois pour ses services cloud.<br />
                    <br />

                    <ul><strong>Facturation à l'heure</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            
                            Lorsque vous choisissez la facturation à l'heure, vous payez en fonction du temps pendant lequel vos instances de machines virtuelles sont actives. <br />
                            Les tarifs sont généralement calculés par heure, ce qui signifie que vous êtes facturé pour chaque heure complète pendant laquelle vos instances sont en cours d'exécution.<br />
                            <br />
                            Par exemple, si vous démarrez une instance à 9h00 et l'arrêtez à 10h30, vous serez facturé pour deux heures complètes (9h00 à 10h00 et 10h00 à 11h00), même si l'instance n'a été active que pendant 1h30.
                            
                        </li>
                    </ul>
                    <ul><strong>Facturation au mois</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Avec la facturation au mois, vous êtes facturé pour l'utilisation de vos instances sur une base mensuelle. <br />
                            Vous choisissez une configuration d'instance et payez un tarif fixe pour cette configuration chaque mois, indépendamment du temps réel pendant lequel les instances sont en cours d'exécution.<br />
                            <br />
                            Par exemple, si vous avez une instance active pendant 10 jours complets dans un mois, vous paierez le même montant que si cette instance avait été active en continu pendant tout le mois.
                        </li>
                    </ul>
                   
                </p>


                <h2 className="article-title-2">Le moins cher des serveurs</h2>
                <p className="article-text"> Le modèle S1-2 est proposé à un super prix et peut être changer à tout moment.</p>
                <img src="/static/image/articles/OvhCloudServerLowCost/Screenshot_20230806_151807.png" alt="" />
                


                <h2 className="article-title-2">L'API OVH</h2>
                <p className="justify">
                    Les instances Public Cloud permettent aux utilisateurs de gérer et de contrôler leurs ressources Cloud de manière automatisée en utilisant des requêtes HTTP. Cette API offre une façon puissante et flexible de gérer vos instances, vos volumes, vos réseaux et d'autres ressources dans le cloud d'OVH sans avoir à passer par l'interface graphique du portail client.
                </p>

                

                <h3 className="article-title-2">Exemples d'utilisation de l'API</h3>

                <p className="article-text">Voici un petit tour rapide de comment fonctionne l'API OVH</p>

                <p>Lister les instances depuis le shell</p>
                <Code language="bash" tabs="5"> 
                {`
                    curl -X GET \\
                    -H "X-Ovh-Application: VOTRE_APPLICATION_KEY" \\
                    -H "Authorization: Bearer VOTRE_CONSUMER_KEY" \\
                    https://eu.api.ovh.com/1.0/cloud/project/VOTRE_PROJECT_ID/instance
                  
                `}
                </Code>

                <p>Supprimer une instance depuis le shell</p>
                <Code language="bash" tabs="5"> 
                {`
                    curl -X DELETE \\
                    -H "X-Ovh-Application: VOTRE_APPLICATION_KEY" \\
                    -H "Authorization: Bearer VOTRE_CONSUMER_KEY" \\
                    "https://api.ovh.com/1.0/cloud/project/VOTRE_PROJECT_ID/instance/ID_DE_L_INSTANCE" 
                    `}
                </Code>


                <p>Lister les instances depuis le nodejs</p>
                <Code language="javascript" tabs="5"> 
                {`
                    const axios = require('axios');

                    // Remplacez ces valeurs par vos propres informations
                    const endpoint = 'https://api.ovh.com/1.0';
                    const applicationKey = 'VOTRE_CLE_APPLICATION';
                    const applicationSecret = 'VOTRE_SECRET_APPLICATION';
                    const consumerKey = 'VOTRE_CLE_CONSOMMATEUR';
                    const serviceName = 'VOTRE_NOM_DE_SERVICE'; // Par exemple, 'your-service-name'
                    
                    // Configurez les en-têtes d'authentification
                    const headers = {
                      'X-Ovh-Application': applicationKey,
                      'X-Ovh-Consumer': consumerKey,
                    };
                    
                    // Fonction pour lister les instances
                    async function listInstances() {
                      try {
                        const response = await axios.get(\`$\{endpoint\}/cloud/project/$\{serviceName\}/instance\`, { headers });
                        return response.data;
                      } catch (error) {
                        console.error('Erreur lors de la récupération des instances :', error);
                        return [];
                      }
                    }
                    
                    // Appel de la fonction pour lister les instances
                    listInstances().then(instances => {
                      console.log('Instances trouvées :', instances);
                    });
                    
                `}
                </Code>

                <div>
                    Pour comprendre plus en detail la logique de l'API OVH je vous invite à explorer l'API à cette adresse <a href="https://api.ovh.com/console/" target="_blank" rel="noopener noreferrer">https://api.ovh.com/console/</a>  
                    <br />
                    Ça permet aussi d'obtenir un clé API rapidement pour un premier test.
                    <p></p>          
                </div>
            </div>



        </main>
        )
    }
} 