import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class Wlan0Config extends React.Component
{
    
    render() 
    {
        
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>

            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Re-configurer le wifi sur Apline Linux</h1>

                <p className="article-text">
                    Cette configuration à été tester sur Alpine Linux. il se pourrait que ça fonctionne aussi sur Ubuntu.
                </p>

                <h2 className="article-title-2">Désactiver l'interface</h2>
                <p className="article-text">Avant de réactiver l'interface avec les nouvelles configurations, désactivez-la en utilisant la commande ifdown</p>
                <Code language="bash" tabs="5"> 
                {`
                    sudo ifdown wlan0
                `}
                </Code>

                <h2 className="article-title-2">Configurer le fichier de configuration</h2>
                <p className="article-text">
                    Configurer les paramètres réseau dans le fichier de configuration /etc/network/interfaces. <br />
                    Utilisez un éditeur de texte (tel que vi, nano ou emacs) pour ouvrir le fichier de configuration.<br />
                    Replacer les lignes pour configurer l'interface wlan0 par :
                </p>
                <Code language="bash" tabs="5"> 
                {`
                    auto wlan0
                    iface wlan0 inet dhcp
                        wpa-ssid VOTRE_SSID
                        wpa-psk VOTRE_MOT_DE_PASSE
                    
                `}
                </Code>
                
                <h2 className="article-title-2">Configurer le fichier de configuration</h2>
                <p className="article-text">
                    Configurer les paramètres réseau dans le fichier de configuration /etc/network/interfaces. <br />
                    Utilisez un éditeur de texte (tel que vi, nano ou emacs) pour ouvrir le fichier de configuration.<br />
                    Replacer les lignes pour configurer l'interface wlan0 par :
                </p>
                <Code language="bash" tabs="5"> 
                {`
                    auto wlan0
                    iface wlan0 inet dhcp
                        wpa-ssid VOTRE_SSID
                        wpa-psk VOTRE_MOT_DE_PASSE
                    
                `}
                </Code>


                <h2 className="article-title-2">Activer l'interface</h2>
                <p className="article-text">
                    Utilisez la commande ifup pour activer l'interface avec la nouvelle configuration :
                </p>
                <Code language="bash" tabs="5"> 
                {`
                    sudo ifup wlan0
                `}
                </Code>

            </div>
        </main>
        )
    }
} 