import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class BurnoutInformatique extends React.Component
{
    
    render() 
    {
        
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/common.css" />
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>

            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Attention au burnout informatique</h1>

                <p className="article-text">
                    Pour une fois le bug c'est vous.
                </p>

                <h2 className="article-title-2">Qu'est ce que c'est ?</h2>
                <div className="article-text justify">

                    Le burnout est un état d'épuisement physique, émotionnel et mental qui peut survenir en raison d'un stress chronique lié au travail. 
                    Il peut toucher n'importe quel domaine professionnel, y compris l'informatique. 
                    Les professionnels de l'informatique, en particulier ceux qui travaillent dans des environnements exigeants, peuvent être confrontés à des défis uniques qui peuvent contribuer au burnout.<br />
                    <br />
                    Voici quelques raisons pour lesquelles les professionnels de l'informatique pourraient être susceptibles de ressentir un épuisement professionnel :

                    <ul><strong>Pression et délais serrés</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Les projets informatiques peuvent être soumis à des délais stricts, ce qui peut entraîner une pression intense pour respecter les échéances.
                        </li>
                    </ul>

                    <ul><strong>Charge de travail élevée</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Les équipes informatiques peuvent souvent être amenées à gérer plusieurs projets en même temps, ce qui peut entraîner une charge de travail excessive.
                        </li>
                    </ul>

                    <ul><strong>Complexité technique</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            La nature complexe des systèmes informatiques et des technologies peut entraîner des défis techniques et des problèmes difficiles à résoudre, ce qui peut être source de stress.
                        </li>
                    </ul>

                    <ul><strong>Disponibilité 24/7</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Dans certains cas, les professionnels de l'informatique peuvent être tenus de travailler en dehors des heures normales de bureau pour gérer les problèmes et les pannes.
                        </li>
                    </ul>
                    
                    <ul><strong>Pression pour rester à jour</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Le domaine de la technologie évolue rapidement, et les professionnels de l'informatique peuvent se sentir obligés de rester constamment à jour sur les nouvelles technologies et les meilleures pratiques.
                        </li>
                    </ul>
                    
                    <ul><strong>Conflits et communications difficiles</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Travailler en équipe et collaborer avec d'autres départements peut parfois entraîner des conflits et des difficultés de communication.
                        </li>
                    </ul>
                    
                    <ul><strong>Manque de reconnaissance</strong> 
                        <li style={{paddingLeft: '2rem',paddingTop: '1rem' }}>
                            Le manque de reconnaissance pour les réalisations et les efforts fournis peut également contribuer à l'épuisement professionnel.
                        </li>
                    </ul>
                    
                    <div>
                        <strong>Conseil</strong>
                        <p className="justify">
                            Pour faire face au burnout informatique et éviter qu'il ne devienne un problème majeur, il est important pour les professionnels de l'informatique de prendre soin de leur bien-être physique et mental. Cela peut inclure la gestion du stress, la prise de pauses régulières, la délégation des tâches lorsque c'est possible, la recherche de soutien auprès de collègues et de mentors, et la recherche d'un équilibre entre le travail et la vie personnelle.
                            Si vous ou quelqu'un que vous connaissez ressentez des symptômes d'épuisement professionnel, il est recommandé de rechercher un soutien professionnel, comme un conseiller ou un professionnel de la santé mentale, pour obtenir de l'aide et des conseils appropriés.
                        </p>
                    </div>

                </div>
            </div>
        </main>
        )
    }
} 