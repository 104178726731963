import React from "react";
import { FaCloudDownloadAlt } from 'react-icons/fa';

export default class HomePage extends React.Component
{
    
    render() 
    {
        return (
            <main>
                <div className="into">
                    
                    <p className="card">
                        Ce site web a été créé pour exposer mon savoir-faire de développeur web.<br />
                        Ce site web respect les normes HTML du W3C et la syntaxe xHTMl imposé par ReactJS.
                    </p>
                </div>
                <div className="card">
                    <p>Certification Code In Game</p>
                    <img src="/static/image/codingame_certification_js.svg" alt="codingame certification js" />
                </div>

                <div style={{textAlign: 'center', margin: '2rem 0', fontSize: '1.6rem'}}>
                    <a href="/dl/test-tosa-php.pdf" target="_blank">
                        Obtenir le resultat du test TOSA PHP<br />
                        <FaCloudDownloadAlt size="64" />
                    </a>
                </div>
            </main>
        )
    }
} 