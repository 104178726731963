import React from "react";


// const server = 'http://192.168.1.202:9999';
const server = 'http://codeurh24.com:9999';

export default class Video extends React.Component
{

    constructor()
    {
        super();

        this.state = {}
    }

    shouldComponentUpdate()
    {
        this.forceUpdate();
    }

    
    render() 
    {
        
        return (
            <video controls width="250">
                <source src={`${server}/pat/Paw%20Patrol.S02E${this.props.video_id}.TRUEFRENCH.1080p.WEB-DL.H264-FTMVHD.mkv`} type="video/mp4" />
            </video>
        )
    }
} 