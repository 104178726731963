import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class CertbotCreate extends React.Component
{
    
    render() 
    {
        
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>

            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Installer le HTTPS Gratuit sur un site web</h1>

                <p className="article-text">
                    Pour suivre cet article il est indispensable de connaitre linux et l'installation de base de nginx<br />
                    Les certificats sont à renouveller tous les 3 mois
                </p>

                <h2 className="article-title-2">Installation avec aptitude</h2>
                <p className="article-text">Indispensable notamment sur rapsberry pi</p>
                <Code language="bash" tabs="5"> 
                {`
                    sudo apt-get install certbot
                    certonly --standalone -d $CERTBOT_DOMAIN --non-interactive --agree-tos -m $EMAIL_ADDRESS
                `}
                </Code>

                <h2 className="article-title-2">Sans installation apt ou snap en passant par Docker</h2>
                <p className="article-text">Sur un pc avec architecture amd64 docker peut être utilisé</p>
                <Code language="bash" tabs="5"> 
                {`
                    docker run -it --rm --name certbot -p 80:80 \\
                    -v "/etc/letsencrypt:/etc/letsencrypt" \\
                    -v "/var/lib/letsencrypt:/var/lib/letsencrypt" \\
                    -v "/var/log/letsencrypt:/var/log/letsencrypt" \\
                    certbot/certbot certonly --standalone -d $CERTBOT_DOMAIN --non-interactive --agree-tos -m $EMAIL_ADDRESS
                `}
                </Code>


                <h2 className="article-title-2">Mise en place des certificats avec nginx</h2>
                <Code language="bash" tabs="5"> 
                {`
                    ssl_certificate           /etc/letsencrypt/live/domaine.com/fullchain.pem;
                    ssl_certificate_key       /etc/letsencrypt/live/domaine.com/privkey.pem;
                    ssl_trusted_certificate   /etc/letsencrypt/live/domaine.com/chain.pem;
                    
                    ssl_protocols             TLSv1 TLSv1.1 TLSv1.2;
                `}
                </Code>

                <h2 className="article-title-2">Rechargement de la configuration nginx</h2>
                <p className="article-text">Ce rechargement permet de savoir si la nouvelle configuration est valide et prend en compte cette nouvelle configuration</p>
                <Code language="bash" tabs="5"> 
                {`
                    nginx -s reload
                `}
                </Code>

                <h2 className="article-title-2">Renouvellement des certificats</h2>
                <p className="article-text">
                        Commande de base.<br />
                        Attention le renouvellement forcé peux durée jusqu'a environ 10min.<br /> 
                        Ce temps est aléatoire pour éviter les ddos.<br />
                        Le suivi des logs de letsencrypt permet de connaitre le temps qui nous est accordé avant le renouvellement exécuté.<br />
                </p>
                <Code language="bash" tabs="5"> 
                {`
                    renew --cert-name domain.com --force-renewal
                `}
                </Code>

            </div>
        </main>
        )
    }
} 