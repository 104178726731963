import App from './App';

import HomePage from './pages/homePage';
import CodingamePage from './pages/CodingamePage';
import Ticks from './pages/Ticks';
import CertbotCreate from './pages/TIcks/CertbotCreate';
import IndexedDB from './pages/TIcks/IndexedDB';
import Btrfs from './pages/TIcks/Btrfs';
import Wlan0Config from './pages/TIcks/Wlan0Config';
import OvhCloudServerLowCost from './pages/TIcks/OvhCloudServerLowCost';
import BurnoutInformatique from './pages/TIcks/BurnoutInformatique';
import ShortcutKeyboard from './pages/TIcks/ShortcutKeyboard';
import Movies from './pages/movies/Movies';


const routes = [
    {
        path: "/",
        element: <App><HomePage /></ App>,
    },
    {
        path: "/codin-game",
        element: <App><CodingamePage /></ App>,
    },
    {
      path: "/articles",
      element: <App><Ticks /></ App>,
    },
    {
      path: "/articles/installer-le-https-gratuit-sur-un-site-web",
      element: <App><CertbotCreate /></ App>,
    },
    {
      path: "/articles/du-no-sql-dans-le-navigateur-web",
      element: <App><IndexedDB /></ App>,
    },
    {
      path: "/articles/faire-des-backups-intélligents",
      element: <App><Btrfs /></ App>,
    },
    {
      path: "/articles/re-configurer-le-wifi-sur-apline-linux",
      element: <App><Wlan0Config /></ App>,
    },
    {
      path: "/articles/un-serveur-dédié-pour-a-peine-5-euros-par-mois",
      element: <App><OvhCloudServerLowCost /></ App>,
    },
    {
      path: "/articles/attention-au-burnout-informatique",
      element: <App><BurnoutInformatique /></ App>,
    },
    {
      path: "/articles/les-raccourcis-clavier-un-atout-précieux",
      element: <App><ShortcutKeyboard /></ App>,
    },
    {
      path: "/movies",
      element: <App><Movies /></ App>,
    },
];

export default routes;