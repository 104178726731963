import React from "react";
import {Helmet} from "react-helmet";

import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { ReactDimmer } from 'react-dimmer';
import Home from "./pages/homePage";
import CodingamePage from "./pages/CodingamePage";
import SideNav from "./components/SideNav";

class App extends React.Component
{

    constructor(props)
    {
        super(props);
        
        this.state = {
            isMenuOpen: false
        }
    }

    handleMenu() {
        let {isMenuOpen} = this.state;

        this.setState({isMenuOpen: !isMenuOpen})
    }


    render() {
        


        return (
            <>
            <Helmet>
                <link rel="stylesheet" href="/static/css/common.css" />
                <link rel="stylesheet" href="/static/css/background-page.css" />
                <link rel="stylesheet" href="/static/css/home.css" />
            </Helmet>

            <div class="blob-c">
                <div class="shape-blob"></div>
                <div class="shape-blob one"></div>
            </div>
            
            
            <SideNav isMenuOpen={this.state.isMenuOpen} handleMenu={e => this.handleMenu()} />
            
            

            <div className={`page ${this.state.isMenuOpen ? "menu-open" : ""}`} id="home-page">
                
                <nav>
                    <GiHamburgerMenu className="menu-btn" onClick={e => this.handleMenu()} />
                </nav>

                <header>
                    <h1 className="h1 header-title-primary">
                        Florent Corlouër
                    </h1>
                    <h2 className="header-title-secondary">
                        Développeur fullstack - DevOps - Analyste Développeur
                    </h2>
                </header>
                
                {this.props.children}
                
                <footer>
                    <img id="img-w3c-validator" src="/static/image/w3c-validator-1.png" alt="validator w3c html5 validation" style={{padding: '1rem'}} />
                    <div>
                        Site web fait par Florent Corlouer sans CMS. <br />
                        Design Fait par Florent Corlouer inspiré du certificat de CodinGame
                    </div>
                </footer>

                <ReactDimmer isOpen={this.state.isMenuOpen} exitDimmer={e => this.handleMenu()} />
            </div>
            </>
        );
      }
}

export default App;
