import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, nord} from 'react-code-blocks';
import { Link } from "react-router-dom";

export default class Ticks extends React.Component
{
    
    render() 
    {
        
        return (
            <main>

                <ul className="card">
                    <li><Link to={'/articles/installer-le-https-gratuit-sur-un-site-web'}>Installer le HTTPS Gratuit sur un site web</Link></li>
                    <li><Link to={'/articles/du-no-sql-dans-le-navigateur-web'}>Du noSQL dans le navigateur web</Link></li>
                    <li><Link to={'/articles/faire-des-backups-intélligents'}>Faire des backups intélligents</Link></li>
                    <li><Link to={'/articles/re-configurer-le-wifi-sur-apline-linux'}>Re-configurer le wifi sur Apline Linux</Link></li>
                    <li><Link to={'/articles/un-serveur-dédié-pour-a-peine-5-euros-par-mois'}>Un serveur dédié pour à peine 5 euros par mois</Link></li>
                    <li><Link to={'/articles/attention-au-burnout-informatique'}>Attention au burnout informatique</Link></li>
                    <li><Link to={'/articles/les-raccourcis-clavier-un-atout-précieux'}>Les raccourcis clavier, un atout précieux</Link></li>
                </ul>
            </main>
        )
    }
} 