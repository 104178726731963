import React from "react";

import { Helmet } from "react-helmet";
import Video from "./Video";



export default class Movies extends React.Component
{

    constructor()
    {
        super();

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const ep = urlParams.get('ep')

        this.state = {
            video_id: ep ? ep : '01'
        }
    }


    loadEp(e)
    {
        // this.setState({video_id: e.target.dataset.id}) 
        window.location.href = `?ep=${e.target.dataset.id}`
          
    }

    render() 
    {
        
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
                <link rel="stylesheet" href="/static/css/movies.css" />
            </Helmet>

            <div>

                {
                    this.state.video_id ? 
                        <div>{this.state.video_id}</div>
                        :
                        <div>Video par defaut</div>
                }


                <ul> Saison 2
                    {
                    [
                        '01','02','03'
                    ].map((object, i) => <li data-id={object} onClick={this.loadEp.bind(this)}>Ep {object}</li> )
                    }
                </ul>
                
                {/* <video controls width="250">
                    <source src={`${server}/pat/Paw%20Patrol.S02E${this.state.video_id}.TRUEFRENCH.1080p.WEB-DL.H264-FTMVHD.mkv`} type="video/mp4" />
                </video> */}
                <Video video_id={this.state.video_id} />
                
            </div>
        </main>
        )
    }
} 