import React from "react";
// https://react-code-blocks-rajinwonderland.vercel.app/?path=/story/codeblock--supported-languages
import { CodeBlock, sunburst, a11yDark} from 'react-code-blocks';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Code from "./components/Code";



a11yDark.backgroundColor = "#2b2b2b00"


export default class ShortcutKeyboard extends React.Component
{
    
    render() 
    {
        
        return (
           

        <main>
            <Helmet>
                <link rel="stylesheet" href="/static/css/ticks/ticks.css" />
            </Helmet>

            <p>
                {'>'} <Link to="/articles" >Les articles</Link>
            </p>


            <div className="card">
                <h1 className="article-title-1">Les raccourcis clavier, un atout précieux.</h1>

                <p className="article-text justify">
                    les raccourcis clavier sont largement utilisés en développement informatique car ils améliorent l'efficacité, la productivité et la fluidité du flux de travail des développeurs. <br />
                    Ils permettent d'économiser du temps, de naviguer rapidement et d'accomplir des tâches de manière précise et efficace.<br />
                    <br />
                    Pour plusieurs raisons, ils offrent divers avantages aux développeurs. <br /><br />
                    Voici quelques raisons pour lesquelles les raccourcis clavier sont populaires dans le développement informatique : <br />
                </p>

                <ol>
                    <li>

                        <strong>Efficacité et Productivité </strong> : 
                        Les raccourcis clavier permettent d'accomplir des tâches plus rapidement par rapport à l'utilisation de la souris. Au lieu de naviguer à travers les menus et les options avec la souris, les développeurs peuvent effectuer des actions directement à l'aide de combinaisons de touches, ce qui accélère leur flux de travail.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Gain de Temps </strong> : 
                        Dans le développement, les développeurs effectuent fréquemment des tâches répétitives, comme la copie, la découpe, le collage, le formatage du code, etc. Les raccourcis clavier permettent de réduire le temps nécessaire pour accomplir ces tâches.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Navigation Rapide </strong> : 
                        Les raccourcis clavier permettent aux développeurs de naviguer rapidement dans leur code. Ils peuvent sauter d'une ligne à l'autre, d'une section à l'autre ou même entre différents fichiers sans avoir à utiliser la souris pour cliquer et faire défiler.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Conservation du Flux de Travail </strong> : 
                        L'utilisation de la souris peut interrompre le flux de travail d'un développeur, car cela nécessite de passer de l'utilisation du clavier à l'utilisation de la souris. Les raccourcis clavier permettent de maintenir un flux de travail plus continu et fluide.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Précision </strong> : 
                        L'utilisation de raccourcis clavier peut réduire les erreurs humaines en évitant les clics accidentels ou les mauvaises sélections avec la souris.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Apprentissage et Maîtrise </strong> : 
                        Les développeurs qui maîtrisent les raccourcis clavier peuvent augmenter leur efficacité et leur expertise. Cela peut également montrer un haut niveau de compétence et de familiarité avec les outils de développement.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Environnement Terminal  </strong> : 
                        Dans les environnements de ligne de commande et de terminal, les raccourcis clavier sont souvent la principale méthode de navigation et d'interaction, car l'utilisation de la souris n'est généralement pas aussi pratique.
                        <br />
                        <br />
                    </li>
                </ol>

                <h2 className="article-title-2">Les raccourcis</h2>

                <h3 className="article-title-3">Raccourcis générales</h3>
                <p>
                    Je vais vous lister les raccourcis que j'utilise le plus sur un ordinateur.
                </p>

                <ul>
                    <li>
                        <strong>Alt + Tab</strong> : 
                        C'est le raccourcis les plus couramment utilisés pour basculer rapidement entre les fenêtres ouvertes. <br />
                        Surtout pour de dev informatique sur un seul écran. <br />
                        Maintenez la touche "Alt" enfoncée et appuyez sur la touche "Tab" pour faire défiler les fenêtres ouvertes.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + Tab</strong> : 
                        Si plusieurs applications sont ouvertes dans une fenêtre d'application (comme dans un navigateur web avec plusieurs onglets), cela permet de basculer entre les onglets.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + F4</strong> : 
                        Ferme la fenêtre active.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Touche Ctrl enfoncée</strong> : 
                        En effectuant une sélection de texte, ça permet de sélectionner des mots individuels au lieu de la sélection de caractères.<br />
                        En effectuant un déplacement dans le texte, ça permet de déplacer le curseur entre les mots individuels au lieu de se déplacer entre les caractères.<br />
                        En effectuant une suppression avec la touche "back" dans le texte, ça permet de supprimer mot par mot au lieu de supprimer caractères par caractères.<br />
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Window + Flèche Gauche / Droite / Haut / Bas</strong> : 
                        Ce raccourci permet de déplacer la fenêtre vers la gauche, la droite, le bas ou le haut de l'écran, 
                        en redimensionnant la fenêtre pour occuper la moitié de l'écran. 
                        C'est très pratique pour organiser les fenêtres côte à côte sur un écran large.
                        Si vous avez plusieurs écrans, ce raccourci peut être utilisé pour déplacer la fenêtre active d'un écran à un autre.
                        <br />
                        <br />
                    </li>
                    
                    <li>
                        <strong>Ctrl + W ou clique avec le bouton molette de la souris</strong> : 
                        ferme l'onglet actif
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + Shift + T</strong> : 
                        Depuis le terminal "konsole" ce raccourci permet d'avoir une nouvelle onglet console pour disposer de n + 1 terminal. <br />
                        Depuis le navigateur chrome ce raccourci permet de retrouver le dernier onglet fermé.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + a</strong> : 
                        Permet de sélectionner tout le contenu disponible
                        <br />
                        <br />
                    </li>
                    
                    
                </ul>

                <h3 className="article-title-3">Raccourcis VS Code</h3>

                <ul>
                    <li>
                        <strong>Ctrl + S</strong> : 
                        Enregistrer le fichier en cours.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + N</strong> : 
                        Nouveau fichier.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + W</strong> : 
                        Fermer l'onglet actuel
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + Tab</strong> : 
                        Basculer entre les onglets ouverts.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + Shift + F</strong> : 
                        Rechercher dans tous les fichiers du projet.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + E</strong> : 
                        Acces rapide par recherche de nom de fichier.
                        <br />
                        <br />
                    </li>
                    <li>
                        <strong>Ctrl + Shift + flèche (haut ou bas)</strong> : 
                        Copie la selection vers le haut ou vers le bas.<br />
                        Ce raccourci est pratique lorsque vous souhaitez réorganiser rapidement le contenu d'un fichier sans avoir à copier et coller la selection.
                        <br />
                        <br />
                    </li>
                    
                    <li>
                        <strong>Alt + flèche (haut ou bas) </strong> : 
                        permet de déplacer une selection de code vers le haut ou vers le bas.<br />
                        Ce raccourci est pratique lorsque vous souhaitez réorganiser rapidement le contenu d'un fichier sans avoir à couper et coller la selection.
                        <br />
                        <br />
                    </li>
                    
                </ul>


            </div>
        </main>
        )
    }
} 